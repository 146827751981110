import '../css/login.css';


window.onload = function() {
    document.getElementById('change_password').addEventListener('click', function () {
        if (document.getElementById('password').getAttribute('type') === 'password') {
            document.getElementById('change_password').className = 'fas fa-eye';
            document.getElementById('password').setAttribute('type', 'text');
        } else {
            document.getElementById('change_password').className = 'fas fa-eye-slash';
            document.getElementById('password').setAttribute('type', 'password');
        }
    });
};